import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'onv-homepage',
  templateUrl: './homepage.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HomepageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
