import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'onv-about-me',
  templateUrl: './about-me.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AboutMeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
